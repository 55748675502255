/* SECTION CONTROL
--------------------------------------------------------------------------------------------*/
[data-s-id="home-toptasks"] {
    margin-top: -60px;
    position: relative;
    z-index: 22;
    @include breakpoint(large) {
        margin-top: -110px;
    }
}

[data-s-id="home-services"] {
    padding-top: 40px;
    padding-bottom: 120px;
}