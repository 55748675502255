/* PART - SOCIALS
--------------------------------------------------------------------------------------------*/

$ac-socials-icon-size: 4em;
$ac-socials-icon-padding: .5em;
$ac-socials-icon-background: $brand-black;
$ac-socials-icon-border-radius: 100%;
$ac-socials-item-margin: .6em; // left or right, deoending on alignment

.ac_socials {
    display: flex;
    @include breakpoint(small only) {
        justify-content: center;
    }
    &.v_smaller {
        .ac_socials_link_icon {
            $ac-socials-icon-size: $ac-socials-icon-size * .75;
            $ac-socials-icon-padding: $ac-socials-icon-padding * .75;

            width: $ac-socials-icon-size;
            height: $ac-socials-icon-size;
            padding: $ac-socials-icon-padding;
            svg {
                width: $ac-socials-icon-size - ($ac-socials-icon-padding * 2);
                height: $ac-socials-icon-size - ($ac-socials-icon-padding * 2);
            }    
        }
    }
    &.v_smallest {
        .ac_socials_link_icon {
            $ac-socials-icon-size: $ac-socials-icon-size * .5;
            $ac-socials-icon-padding: $ac-socials-icon-padding * .5;

            width: $ac-socials-icon-size;
            height: $ac-socials-icon-size;
            padding: $ac-socials-icon-padding;
            svg {
                width: $ac-socials-icon-size  - ($ac-socials-icon-padding * 2);
                height: $ac-socials-icon-size - ($ac-socials-icon-padding * 2);
            }    
        }
    }
    &.v_hide_text {
        .ac_socials_link_text {
            display: none;
        }
    }
    &.v_collapse-on-small {
        @include breakpoint(small only) {
            flex-direction: column;
            .ac_socials_link {
                margin-bottom: $ac-socials-item-margin;
            }
        }
    }
}   
.ac_socials_link {
    display: flex;
    align-items: center;
    margin-right: $ac-socials-item-margin / 2;
    margin-left: $ac-socials-item-margin / 2;
}
.ac_socials_link_icon {
    width: $ac-socials-icon-size;
    height: $ac-socials-icon-size;
    padding: $ac-socials-icon-padding;
    background: $ac-socials-icon-background;
    border-radius: $ac-socials-icon-border-radius;
    display: flex;
    flex-direction: column;
    justify-content: center;
    transition: all $default-transition-speed;

    svg {
        width: $ac-socials-icon-size - ($ac-socials-icon-padding * 2);
        height: $ac-socials-icon-size - ($ac-socials-icon-padding * 2);
        fill: $brand-white;
        transition: all $default-transition-speed;
    }    
}
.ac_socials_link_text {
    margin-left: $ac-socials-item-margin;
}