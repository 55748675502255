/* GENERICS - FORM
--------------------------------------------------------------------------------------------*/
input,
textarea,
select {
    
    border-color: rgba($brand-tertiary, .3) !important;
    border-radius: 3px !important;
    padding: 0rem 2rem .2rem 1rem !important;
    height: 3rem !important;

    &:disabled {
        background-color: transparent;
        border-color: rgba($brand-tertiary, .15) !important;
        color: $brand-tertiary !important;
        opacity: 1 !important;
    }
    &::placeholder {
       font-style: italic;
       color: rgba($brand-tertiary, .6) !important;
    }

    &:focus {
        border-color: rgba($brand-tertiary, 1) !important;
    }
}

select {
    padding: 0rem 2.5rem 0 1rem !important;
    background-position: right -18px center;
}
textarea {
    min-height: 100px;
}

input[type="submit"],
input[type="button"]{
     padding: 1rem 1rem 1rem 1rem !important;
     border: solid 3px $brand-light-gray !important;
}

input::-webkit-contacts-auto-fill-button {
    visibility: hidden;
    display: none !important;
    pointer-events: none;
    position: absolute;
    right: 0;
}
.input-group-button a, .input-group-button input, .input-group-button button, .input-group-button label {
    font-size: 0.9rem;
}