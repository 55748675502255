
/* AC_S_BRANDS - GLOBALS
--------------------------------------------------------------------------------------------*/



//
//      SECTION BRANDS
//


[data-s-type="brands"] {

    position: relative;
    padding: $default-padding 0;
    
    @include breakpoint(medium) {
        padding: 0 0 $default-padding / 2 0;
    }   
}


//
//      CONTAINER
//

.ac_brands_container{
    // max-width: $max-content-1200;
    position: relative;
    z-index: 2;
    margin: 2em auto;
    display: flex;
    flex-wrap: wrap;

    @if $ac-brands-items-have-border-dividers == false and $ac-brands-items-have-padding == true {
        padding: 0 $default-padding / 2;
    }
}


//
//      ITEM
//

.ac_brands_item {
    display: flex;
    position: relative;
    flex-direction: column;
    margin-bottom: $ac-brands-item-margin-bottom;

    @if $ac-brands-items-on-small-two-items-a-row == true {
        width: percentage(1/2);
        max-width: percentage(1/2);
    } @else {
        width: 100%;
        max-width: 100%;
    }

    @if $ac-brands-items-have-padding == true {
        padding: 0 $default-padding;
    } @else {
        padding: 0;
    }

    @if $ac-brands-items-have-border-dividers == true {
        @if $ac-brands-items-have-padding == true {
            padding: 0 $default-padding;
        }
        border-left: $ac-brands-item-border;
        
    } @else {
        @if $ac-brands-items-have-padding == true {
            padding: 0 $default-padding / 2;
        }
    }   

    &.v_smaller-logo {
        .ac_brands_item_logo {
            transform: scale(.8);
        }
    }
    &.v_larger-logo {
        .ac_brands_item_logo {
            transform: scale(1.2);
        }
    }

    &:hover {
        @include breakpoint(large) {
            .ac_brands_item_logo {
                opacity: $ac-brands-item-logo-hover-opacity;
            }
            .ac_brands_item_description {
                color: $ac-brands-items-description-hover-color;
            }
        }
    } 

    @if $ac-brands-items-have-border-dividers == true {

        @include breakpoint(small only) {
            @include last(1) {
                .ac_brands_item_container {
                    border-bottom: none;
                }
            }
        }
        
    }

}

.ac_brands_item_container {
    height: 100%;
    position: relative;
    background: $ac-brands-item-content-background;
    padding: $ac-brands-item-container-padding;

    @if $ac-brands-items-have-border-dividers == true {
        border-bottom: $ac-brands-item-border;
    }

    @if $ac-brands-item-shadow == true {
        box-shadow: $default-box-shadow;
    }
}


//
//      BORDER RESPONSIVENESS
//


[data-s-type="brands"] {
    @if $ac-brands-items-have-border-dividers == true {
        
        @if $ac-brands-items-on-small-two-items-a-row == false {

            // border-left
            @include border-responsiveness('S1');
            @include border-responsiveness('S1-MP2');
            @include border-responsiveness('S1-MP2-M3');
            @include border-responsiveness('S1-MP2-M3-L4');
            @include border-responsiveness('S1-MP2-L5');

            // border-bottom
            @include bottom-items-no-border('S1-MP2');
            @include bottom-items-no-border('S1-MP2-L3');
            @include bottom-items-no-border('S1-MP2-L4');
            @include bottom-items-no-border('S1-MP2-L5');

        } @else {

            // border-left
            @include border-responsiveness('S2');
            @include border-responsiveness('S2-M3');
            @include border-responsiveness('S2-L4');
            @include border-responsiveness('S2-L5');

            // border-bottom
            @include bottom-items-no-border('S2');
            @include bottom-items-no-border('S2-M3');
            @include bottom-items-no-border('S2-L4');
            @include bottom-items-no-border('S2-L5');

        }

    }
}


//
//      DESCRIPTION
//

.ac_brands_item_description {
    
    @if $ac-brands-items-description-enable == true {
    text-align: center;
    font-size: 95%;
    color: $ac-brands-items-description-color;
    } @else {
        display: none;
    }
}



//
//      LOGO
//

.ac_brands_item_logo_container {
    display: flex;
    width: 100%;
    justify-content: center;
    @if $ac-brands-items-on-small-two-items-a-row == false {
        padding: 0 10vw;
    }
    @include breakpoint(medium-portrait) {
        padding: 0;
    }
}
.ac_brands_item_logo {
    filter: brightness(50%) hue-rotate(30deg);
    width: 60%;
    height: $ac-brands-item-logo-height;
    max-width: $ac-brands-item-logo-max-width;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    transition: opacity $default-transition-speed;
    opacity: $ac-brands-item-logo-initial-opacity;
}



//
//      SLIDER
//


@if $ac-brands-items-slider == true {
    .ac_brands_container {
        display: block;
        z-index: inherit; // slick slider will handle z-index

        //slick fuoc fix
        max-height: $ac-brands-items-slider-fouc-height;
        overflow: hidden;
        opacity: 0;
        transition: opacity $default-transition-speed;

        // drop fouc if slick is initialized
        &.slick-initialized  {
            max-height: inherit;
            overflow: visible;
            opacity: 1;
        }
    }

    [data-s-amount-item] {
    // because all items are in one line, margin on the bottom make no sense
        margin-bottom: 0;
    }

    [data-border-bottom] {
        border-bottom-color: transparent;
    }

    // overrule border left behaviour
    @if $ac-brands-items-have-border-dividers == true {
        .slick-slide {
            border-left-color: transparent !important;
        }
        .slick-active ~ .slick-active{
            border-left: $ac-brands-item-border !important;
        }
    }

    // if amount on small == 1, we can hide the border all together. This also prevents content grow flickers
    @include breakpoint(small only) {
        [data-s-amount-item] {
            border-left-color: transparent;
        }
    }
}