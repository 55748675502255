/* BACKGROUND COLOR
----------------------------------------------------------------------------------------------------*/

@mixin generate-background-color-classes {
    @each $key, $value in $brand-color-list {
        .a_bg_brand_#{$key} {
            background-color: map-get($brand-color-list, $key) !important;
        }   
    }
}
@include generate-background-color-classes();


// default colors
@mixin generate-default-bg-classes {
    @each $key, $value in $default-bg-list {
        .a_bg_#{$key} {
            background: map-get($default-bg-list, $key) !important;
        }   
    }
}
@include generate-default-bg-classes();


/* BACKGROUND GRADIENTS
----------------------------------------------------------------------------------------------------*/
@mixin generate-background-gradient-classes {
    @each $key, $value in $brand-gradient-list {
        .a_bg_gradient_brand_#{$key} {
            background: map-get($brand-gradient-list, $key) !important;
        }   
    }
}
@include generate-background-gradient-classes();



/* FILL COLOR
----------------------------------------------------------------------------------------------------*/

// all brand colors
@mixin generate-fill-classes {
    @each $key, $value in $brand-color-list {
        .a_fill_brand_#{$key} {
             svg {
                fill: map-get($brand-color-list, $key) !important;
             }
        }   
    }
}
@include generate-fill-classes();

// default colors
@mixin generate-default-fill-classes {
    @each $key, $value in $default-fill-color-list {
        .a_fill_#{$key} {
             svg {
                fill: map-get($default-fill-color-list, $key) !important;
             }
        }   
    }
}
@include generate-default-fill-classes();



/* BORDERS
----------------------------------------------------------------------------------------------------*/
.a_border-top_brand_light-gray {
    border-top: solid 1px $brand-light-gray !important;
}
.a_border-bottom_brand_light-gray {
    border-bottom: solid 1px $brand-light-gray !important;
}



/* TEXT COLOR
----------------------------------------------------------------------------------------------------*/

// all brand colors
@mixin generate-text-color-classes {
    @each $key, $value in $brand-color-list {
        .a_text_brand_#{$key} {
             * {
                color: map-get($brand-color-list, $key) !important;
             }
        }   
    }
}
@include generate-text-color-classes();

// default colors
@mixin generate-default-text-color-classes {
    @each $key, $value in $default-text-color-list {
        .a_text_#{$key} {
             * {
                color: map-get($default-text-color-list, $key) !important;
             }
        }   
    }
}
@include generate-default-text-color-classes();
