
/* AC_S_HERO_ALT - 1
--------------------------------------------------------------------------------------------*/

[data-s-type="hero-alt"] {
    // hero
    $hero-alt-height: 300px; // 100vh, 80vh, 400px, etc
    $hero-alt-min-height: 200px;

    // slide
    $hero-alt-slide-max-width: 1000px;
    $hero-alt-slide-content-correction: -1em; // allows for visual correction of outlining content, when using a divider
    $hero-alt-slide-type: 'text'; // text or text-image
    $hero-alt-slide-align: 'center'; // center or left. text-image always implies left aligned text
    $hero-alt-slide-content-text-width: 1*.8; // // can be any percentage calculation, eg 1*.8 or 1/4. The image (if applicable) will take the space left
    $hero-alt-slide-image-on-small: 'absolute'; // none or absolute; how to show the image on small

    // arrows
    $hero-alt-slide-arrows-enabled: true;
    $hero-alt-slide-arrows-enabled-from: 0px; // 700px
    $hero-alt-slide-increase-arrows-size-from: 1150px;
    $hero-alt-slide-arrows-size-small: 1em;
    $hero-alt-slide-arrows-size-large: 2em;
    $hero-alt-slide-arrows-position: 'bottom-centered'; // left-and-right-centered, bottom-centered
    $hero-alt-slide-arrows-bottom-position: 4em; // if bottom-centered

    $hero-alt-backdrop-till-medium-grayscale: false;
    $hero-alt-backdrop-till-medium-fade: .4;
    $hero-alt-backdrop-till-medium-blend-mode: multiply; // multiply / normal

    $hero-alt-link-to-content-enabled: false;
    $hero-alt-link-to-content-bottom: 3em;
    $hero-alt-link-to-content-show-till-medium: false;


    @import '__hero_alt_globals';


    /*--------------------------------------------------------------------------------------------*/
    
    position: relative;
    h1, h2, h3 {
        line-height: 1;
    }
    p {
        line-height: 1.5;
    }

    * {
        &:focus {
            outline: none;
        }
    }
}