/* GRID - BASED ON DATA ATTRIBUTES
--------------------------------------------------------------------------------------------*/

[data-s-amount="1"] {
    [data-s-amount-item] {
        width: 100%;
    }
}

[data-s-amount="2"] {
    [data-s-amount-item] {
        @include breakpoint(medium-portrait) {
            width: percentage(1/2);
            max-width: percentage(1/2);
        }
    }
}

[data-s-amount="3"] {
    [data-s-amount-item] {
        @include breakpoint(medium-portrait) {
            width: percentage(1/2);
            max-width: percentage(1/2);
        }
    }
     [data-s-amount-item] {
        @include breakpoint(medium) {
            width: percentage(1/3);
            max-width: percentage(1/3);
        }
    }
}

[data-s-amount="4"] {
    [data-s-amount-item] {
        @include breakpoint(medium-portrait) {
            width: percentage(1/2);
            max-width: percentage(1/2);
        }
    }
    [data-s-amount-item] {
        @include breakpoint(large) {
            width: percentage(1/4);
            max-width: percentage(1/4);
        }
    }
}

[data-s-amount="5"] {
    [data-s-amount-item] {
        @include breakpoint(medium-portrait) {
            width: percentage(1/2);
            max-width: percentage(1/2);
        }
    }
    [data-s-amount-item] {
        @include breakpoint(large) {
            width: percentage(1/5);
            max-width: percentage(1/5);
        }
    }
}
