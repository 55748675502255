@charset 'utf-8';

@import 'fonts';
@import 'variables';
@import 'functions';
@import 'mixins';

// foundation
@import 'settings';
@import 'foundation';
@import 'motion-ui';

@include foundation-global-styles;
//@include foundation-grid;
// @include foundation-flex-grid;
@include foundation-typography;
@include foundation-button;
@include foundation-forms;
// @include foundation-range-input;
// @include foundation-accordion;
@include foundation-accordion-menu;
// @include foundation-badge;
// @include foundation-breadcrumbs;
// @include foundation-button-group;
// @include foundation-callout;
// @include foundation-close-button;
// @include foundation-menu;
// @include foundation-menu-icon;
// @include foundation-drilldown-menu;
@include foundation-dropdown;
@include foundation-dropdown-menu;
// @include foundation-label;
// @include foundation-media-object;
// @include foundation-off-canvas;
// @include foundation-orbit;
// @include foundation-pagination;
// @include foundation-progress-bar;
// @include foundation-progress-element;
// @include foundation-meter-element;
@include foundation-responsive-embed;
// @include foundation-slider;
//@include foundation-sticky;
// @include foundation-reveal;
// @include foundation-switch;
// @include foundation-table;
// @include foundation-tabs;
// @include foundation-thumbnail;
// @include foundation-title-bar;
// @include foundation-tooltip;
// @include foundation-top-bar;
@include foundation-visibility-classes;
@include foundation-float-classes;
// @include foundation-flex-classes;

//@include motion-ui-transitions;
//@include motion-ui-animations;

/* SLICK */
@import "bower_components/slick-carousel/slick/slick.scss";

/* PHOTOSWIPE */
// @import "bower_components/photoswipe/src/css/main.scss";

@import 'structure';
@import 'components';
@import 'section-control';

@import 'adjustments';
@import 'brand-adjustments';
@import 'fixes';

