/* STATE HIDDEN
----------------------------------------------------------------------------------------------------*/
.s_hidden {
    display: none !important;
}

/* TEXT ALIGN
----------------------------------------------------------------------------------------------------*/
.a_text-align_left {
    text-align: left !important;
}
.a_text-align_center {
    text-align: center !important;
}
.a_text-align_right {
    text-align: right !important;
}

/* FLEXBOX HELPERS
----------------------------------------------------------------------------------------------------*/
.a_justify_flex-start  {
    justify-content: flex-start !important;
}
.a_justify_center {
    justify-content: center !important;
}
.a_justify_flex-end {
    justify-content: flex-end !important;
}
.a_justify_space-around {
    justify-content: space-around !important;
}
.a_justify_space-between {
    justify-content: space-between !important;
}


.a_align-items_center {
    align-items: center !important;
}
.a_align-items_flex-start {
    align-items: flex-start !important;
}
.a_align-items_flex-end {
    align-items: flex-end !important;
}

.a_flex-direction_row {
    flex-direction: row !important;
}
.a_flex-direction_row-reverse {
    flex-direction: row-reverse !important;
}


/* MAX WIDTH
----------------------------------------------------------------------------------------------------*/
.a_max-width-full {
    max-width: 100% !important;
}

$max-width-list: 600 800 1000 1200 1400 1600 1800 2000;

@mixin generate-max-width-classes {
    @each $item in $max-width-list {
        .a_max-width-#{$item} {
            max-width: ($item + 0px) !important;
            margin: 0 auto;
        }   
    }
}
@include generate-max-width-classes();


/* BACKGROUND ATTACHMENT 
----------------------------------------------------------------------------------------------------*/
.a_background-attachment_fixed { 
    // not a suitable effect for mobile devices
    // http://stackoverflow.com/questions/23236158/how-to-replicate-background-attachment-fixed-on-ios
    @include breakpoint(large) {
        background-attachment: fixed;
    }
}

/* BACKGROUND CONTAIN
----------------------------------------------------------------------------------------------------*/
.a_contain {
    background-size: contain !important;
    background-repeat: no-repeat;
    background-position: center center;
}

/* BACKGROUND COVER
----------------------------------------------------------------------------------------------------*/

$cover-list: ct cc cb lt lc lb rt rc rb;
$positions: (
	ct: 50% 0%,
	cc: 50% 50%,
    cb: 50% 100%,
    lt: 0% 0%,
    lc: 0% 50%,
    lb: 0% 100%,
    rt: 100% 0%,
    rc: 100% 50%,
    rb: 100% 100%
);

@mixin generate-cover-classes {
    @each $item in $cover-list {
        .a_cover-#{$item} {
            background-size: cover !important;
            background-position: map-get($positions, $item)!important;
        }
    }
    @each $item in $cover-list {
        @include breakpoint(medium-portrait) { 
            .a_medium-portrait_cover-#{$item} {
                background-position: map-get($positions, $item)!important;
            }
        }
    }
    @each $item in $cover-list {
        @include breakpoint(medium) { 
            .a_medium_cover-#{$item} {
                background-position: map-get($positions, $item)!important;
            }
        }
    }
}
@include generate-cover-classes();



/* OPACITY
----------------------------------------------------------------------------------------------------*/

$opacity-list: 0 5 10 20 30 40 50 60 70 80 90 100;

@mixin generate-opacity-classes {
    @each $item in $opacity-list {
        .a_opacity-#{$item} {
            opacity: ($item / 100) !important;
        }   
    }
}
@include generate-opacity-classes();


/* GRAYSCALE
----------------------------------------------------------------------------------------------------*/

$grayscale-list: 20 40 60 80 100;

@mixin generate-grayscale-classes {
    @each $item in $grayscale-list {
        .a_grayscale-#{$item} {
            filter: grayscale((0% + $item)) !important;
        }   
    }
}
@include generate-grayscale-classes();



/* BLEND MODES
----------------------------------------------------------------------------------------------------*/

$blend-modes-list: multiply screen overlay exclusion difference;

@mixin generate-blend-modes-classes {
    @each $item in $blend-modes-list {
        .a_blend-#{$item} {
            mix-blend-mode: $item !important;
        }   
    }
}
@include generate-blend-modes-classes();


/* SHADOWS
----------------------------------------------------------------------------------------------------*/
.a_cast-shadow_top {
    box-shadow: 0px -10px 10px rgba(34, 34, 34, 0.1) !important;
}


/* MARGIN HELPERS
----------------------------------------------------------------------------------------------------*/
.a_no-margin {
    margin: 0 !important;
}
.a_no-margin-top {
    margin-top: 0 !important;
}
.a_no-margin-right {
    margin-right: 0 !important;
}
.a_no-margin-bottom {
    margin-bottom: 0 !important;
}
.a_no-margin-left {
    margin-left: 0 !important;
}

$margin-list: 0 10 20 40 60 80 100;
$margin-sides: top bottom; // top right bottom left;

@mixin generate-margin-classes {
    @each $item in $margin-list {
        @each $side in $margin-sides {
            .a_margin-#{$side}-#{$item} {
                margin-#{$side}: (0px + ($item/2)) !important;
            }
        }
    }
    @each $item in $margin-list {
        @each $side in $margin-sides {
            @include breakpoint(medium) {
                .a_margin-#{$side}-#{$item} {
                    margin-#{$side}: (0px + $item) !important;
                }
            }
        }
    }
}
@include generate-margin-classes();


/* PADDING HELPERS
----------------------------------------------------------------------------------------------------*/

.a_no-padding {
    padding: 0 !important;
}
.a_no-padding-top {
    padding-top: 0 !important;
}
.a_no-padding-right {
    padding-right: 0 !important;
}
.a_no-padding-bottom {
    padding-bottom: 0 !important;
}
.a_no-padding-left {
    padding-left: 0 !important;
}

$padding-list: 0 10 20 40 60 80 100 120 140;
$padding-sides: top bottom; // top right bottom left;

@mixin generate-padding-classes {
    @each $item in $padding-list {
        @each $side in $padding-sides {
            .a_padding-#{$side}-#{$item} {
                padding-#{$side}: (0px + ($item/2)) !important;
            }
        }
        @each $side in $padding-sides {
            @include breakpoint(medium) {
                .a_padding-#{$side}-#{$item} {
                    padding-#{$side}: (0px + $item) !important;
                }
            }
        }
    }
}
@include generate-padding-classes();