
/* CTA TEXT-BUTTON
--------------------------------------------------------------------------------------------*/


[data-s-type="cta-text-socials"] {
    
    //columns
    $ac-cta-max-width: $max-content-800;
    $ac-cta-column-first-width: 1*0; // takes any percentage calculation
    $ac-cta-column-first-text-align: 'right'; // left, right or center. Applies to version 1
    $ac-cta-column-second-text-align: 'left'; // left, right or center. Applies to version 1
    $ac-cta-reversed-content: false; // flip columns

    // column gap
    $ac-cta-column-gap-has-divider: false;
    $ac-cta-column-gap: $default-padding;
    $ac-cta-column-gap-divider: solid 1px rgba($default-background-light, .2);
    
    $ac-cta-elements-no-margin: false; // prevents nasty alignment due to margins, not applicable for collapsed view.

    //img
    $ac-img-border-bottom: true;
    $ac-img-border: solid 1px $default-background-light;

    @import '../ac_s__cta-globals/__cta_globals';

    /*--------------------------------------------------------------------------------------------*/
    
    //local variables
    $centered-items-for-medium-up: true;
    $hide-first-column: true;

    /*--------------------------------------------------------------------------------------------*/


    h2 {
        margin-bottom: 20px;
    }

    .ac_cta_container {
        @if $centered-items-for-medium-up == false {
            @include breakpoint(medium) { 
                display: flex;
                align-items: center;
            }
        }
    } 

    .ac_cta_column {
        width: 100%;
        text-align: center;
        &:nth-child(1) {
            @if $ac-cta-column-gap-has-divider == true and $hide-first-column == false{
                padding-bottom: $ac-cta-column-gap;
                border-bottom: $ac-cta-column-gap-divider;
                margin-bottom: $ac-cta-column-gap * 2;
            }
            @if $hide-first-column == true {
                display: none;
            }
        }
                
        @include breakpoint(medium) { 
            @if $centered-items-for-medium-up == false {
                @if $hide-first-column == false {
                    &:nth-child(1) {
                        width: percentage($ac-cta-column-first-width);
                        max-width: percentage($ac-cta-column-first-width);

                        padding-right: $ac-cta-column-gap;
                        padding-bottom: inherit;

                        @if $ac-cta-column-gap-has-divider == true {
                            border-bottom: inherit;
                            margin-bottom: inherit;
                            @if $ac-cta-reversed-content == false {
                                border-right: $ac-cta-column-gap-divider;
                                margin-right: $ac-cta-column-gap;
                            } @else {
                                border-left: $ac-cta-column-gap-divider;
                                margin-left: $ac-cta-column-gap;
                                padding-right: inherit;
                                padding-left: $ac-cta-column-gap;
                            }
                        }

                        text-align: left;
                        @if $ac-cta-column-first-text-align == 'center' {
                            text-align: center;
                        } @else if $ac-cta-column-first-text-align == 'right' {
                            text-align: right;
                        }
                    }
                } 
                &:nth-child(2) {
                    width: 100% - percentage($ac-cta-column-first-width);
                    max-width: 100% - percentage($ac-cta-column-first-width);
                    
                    text-align: left;
                    @if $ac-cta-column-second-text-align == 'center' {
                        text-align: center;
                    } @else if $ac-cta-column-second-text-align == 'right' {
                        text-align: right;
                    }
                }
            }
        }
    }   

    .ac_socials {
        justify-content: space-around;
        flex-wrap: wrap;

    }
    .ac_socials_link {
        padding: $default-padding / 2 $default-padding;

        @include breakpoint(small only) {
            width: 100%;
        }
    }
    // .ac_socials_link_text {
    //     display: none;
    // }


} // end of scope